import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { isValidateEmail } from '@/helpers/validations';

const useAuth = () => {
  const router = useRouter();
  const store = useStore();

  const userForm = ref({
    email: '',
    password: '',
  });

  const validate = ref({
    isLoading: false,
    message: '',
    messageType: 'success',
  });

  const resetValidate = () => {
    validate.value = {
      isLoading: false,
      message: '',
      messageType: 'success',
    };
  };

  const loginUser = async () => {
    const { email, password } = userForm.value;
    resetValidate();

    if (!isValidateEmail(email)) {
      validate.value.messageType = 'error';
      validate.value.message = 'Email inválido';
    } else if (password.length <= 4) {
      validate.value.messageType = 'error';
      validate.value.message = 'La contraseña debe tener más de 4 caracteres';
    } else {
      validate.value.isLoading = true;
      const { status, message } = await store.dispatch('auth/signInUser', {
        email,
        password,
      });
      validate.value.isLoading = false;
      if (!status) {
        validate.value.messageType = 'error';
        validate.value.message = message;
      } else {
        validate.value.messageType = 'success';
        validate.value.message = message;
        setTimeout(() => {
          resetValidate();
          router.push({ name: 'recipes' });
        }, 1400);
      }
    }
  };

  const logoutUser = () => {
    store.commit('auth/logout');
    store.commit('cmsRecipes/clearSelectedRecipe');
    router.push({ name: 'login' });
  };

  return {
    validate,
    userForm,
    resetValidate,
    loginUser,
    logoutUser,
  };
};

export default useAuth;
