import { useStore } from 'vuex';

import useAuth from '@/modules/auth/composables/useAuth';
import { fetchWithToken } from '@/helpers/fetch';

const useCmsListings = () => {
  const store = useStore();
  const { logoutUser } = useAuth();

  const getCmsListings = async () => {
    const { status } = await store.dispatch('auth/checkAuthentication');

    store.commit('cmsIngredients/loadIngredients');
    store.commit('cmsRecipes/loadRecipes');
    store.commit('cmsProducts/loadProducts');

    if (status) {
      try {
        const ingredientPromise = fetchWithToken('/ingredientes');
        const recipesPromise = fetchWithToken('/recetas');
        const productsPromise = fetchWithToken('/productos');

        const [ingredientsResp, recipesResp, productsResp] = await Promise.all([
          ingredientPromise,
          recipesPromise,
          productsPromise,
        ]);

        const ingredients = await ingredientsResp.json();
        const recipes = await recipesResp.json();
        const { productos } = await productsResp.json();

        store.commit('cmsIngredients/updateIngredients', {
          ingredients,
          status: true,
        });

        store.commit('cmsRecipes/updateRecipes', {
          recipes,
          status: true,
        });

        store.commit('cmsProducts/updateProducts', {
          productos,
          status: true,
        });
      } catch (error) {
        console.error(error);
        store.commit('cmsIngredients/updateIngredients', { status: false });
        store.commit('cmsRecipes/updateRecipes', { status: false });
        store.commit('cmsProducts/updateProducts', { status: false });
      }
    } else {
      logoutUser();
    }
  };

  return {
    getCmsListings,
  };
};

export default useCmsListings;
