<template>
  <div class="container-cms scroll">
    <Navbar />
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, onBeforeMount } from 'vue';

import useCmsListings from '@/modules/cms/composables/useCmsListings';

export default {
  components: {
    Navbar: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Navbar" */ '@/modules/cms/components/Navbar.vue'
      )
    ),
  },
  setup() {
    const { getCmsListings } = useCmsListings();
    onBeforeMount(async () => await getCmsListings());
  },
};
</script>
